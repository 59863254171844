import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import CatalogPage from '../components/catalog'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Pipe Markers USA</title>
        <meta property="og:title" content="Cloudy Vacant Lobster" />
      </Helmet>
      <Navbar8
        page4Description={
          <Fragment>
            <span className="home-text10">
              Get in touch with us for pricing and inquiries
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text11">Login</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text12">products</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text13">Home</span>
          </Fragment>
        }
        
        page4={
          <Fragment>
            <span className="home-text15">Contact</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text16">Products</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text17">contact</span>
          </Fragment>
        }
        page1Description={
          <Fragment>
            <span className="home-text18">Welcome to Pipe Markers USA</span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text19">
              Browse our wide selection of pipe markers
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text20">about</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text21">About Us</span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text22">
              Learn more about Pipe Markers USA
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text23">Logout</span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action2={
          <Fragment>
            <span className="home-text24">Contact Us for Pricing</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text25">Browse Catalog</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text26">Welcome to Pipe Markers USA</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text27">
              Browse our wide selection of high-quality pipe markers for various
              applications such as natural gas, cold water, and hot water.
            </span>
          </Fragment>
        }
      ></Hero17>

       
      <CTA26
        heading1={
          <Fragment>
            <span className="home-text28">
              Find the Perfect Pipe Marker for Your Needs
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text29">
              Browse through our extensive catalog of pipe markers for natural
              gas, cold water, hot water, and more.
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text30">Explore Catalog</span>
          </Fragment>
        }
      ></CTA26>


    <CatalogPage>
      
    </CatalogPage>
      <Features25
        feature3Description={
          <Fragment>
            <span className="home-text31">
              Explore our hot water pipe markers that are durable and easy to
              install for clear identification.
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text32">
              Browse our selection of pipe markers specifically designed for
              natural gas pipelines.
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text33">Cold Water Pipe Markers</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text34">Natural Gas Pipe Markers</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text35">
              Discover our range of pipe markers tailored for cold water pipes
              to ensure safety and compliance.
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text36">Hot Water Pipe Markers</span>
          </Fragment>
        }
      ></Features25>
      <Steps2
        step1Description={
          <Fragment>
            <span className="home-text37">
              Explore our wide range of pipe markers for natural gas, cold
              water, hot water, and more.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text38">
              Learn more about Pipe Markers USA and our commitment to providing
              high-quality pipe markers for various applications.
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text39">Request Pricing</span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text40">
              Interested in our products? Reach out to us via email to inquire
              about pricing and place an order.
            </span>
          </Fragment>
        }
        step1Title={
          <Fragment>
            <span className="home-text41">Browse Our Inventory</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text42">About Us</span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text43">
              Exclusive access for the owner of Pipe Markers USA to manage
              inventory, orders, and website content.
            </span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text44">Owner Login</span>
          </Fragment>
        }
      ></Steps2>
    </div>
  )
}

export default Home
