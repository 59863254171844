import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { scrollToSection } from './scrollToSection';
import './navbar8.css';
import ContactForm from './ContactForm'; // Import the ContactForm component
const logo = require('../assets/images/Logo.png');

const Navbar8 = (props) => {
  const [isContactFormVisible, setIsContactFormVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const toggleContactForm = () => {
    setIsContactFormVisible(!isContactFormVisible);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <header className="navbar8-container1">
      <header data-thq="thq-navbar" className="navbar8-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar8-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar8-desktop-menu">
          <nav className="navbar8-links1">
            <span
              className="thq-link thq-body-small"
              onClick={() => scrollToSection('product-catalog')}
            >
              {props.link2 ?? (
                <Fragment>
                  <span className="navbar8-text16">Products</span>
                </Fragment>
              )}
            </span>
            <span className="thq-link thq-body-small">
              {props.link3 ?? (
                <Fragment>
                  <span className="navbar8-text24">About</span>
                </Fragment>
              )}
            </span>
            <span
              className="thq-link thq-body-small"
              onClick={toggleContactForm}
            >
              {props.link4 ?? (
                <Fragment>
                  <span className="navbar8-text21">Contact</span>
                </Fragment>
              )}
            </span>
          </nav>
          <div className="navbar8-buttons1">
            <button className="navbar8-action11 thq-button-animated thq-button-filled">
              <span>
                {props.action1 ?? (
                  <Fragment>
                    <span className="navbar8-text15">Login</span>
                  </Fragment>
                )}
              </span>
            </button>
            <button className="navbar8-action21 thq-button-outline thq-button-animated">
              <span>
                {props.action2 ?? (
                  <Fragment>
                    <span className="navbar8-text27">Logout</span>
                  </Fragment>
                )}
              </span>
            </button>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="navbar8-burger-menu"
          onClick={toggleMobileMenu}
        >
          <svg viewBox="0 0 1024 1024" className="navbar8-icon14">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
      </header>

      {isMobileMenuVisible && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu-content">
            <button className="close-button" onClick={toggleMobileMenu}>
              &times;
            </button>
            <nav className="mobile-menu-links">
              <span
                className="mobile-link"
                onClick={() => {
                  scrollToSection('product-catalog');
                  toggleMobileMenu();
                }}
              >
                Products
              </span>
              <span className="mobile-link">About</span>
              <span className="mobile-link" onClick={toggleContactForm}>
                Contact
              </span>
            </nav>
          </div>
        </div>
      )}

      {isContactFormVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={toggleContactForm}>
              &times;
            </button>
            <ContactForm />
          </div>
        </div>
      )}
    </header>
  );
};

Navbar8.defaultProps = {
  logoAlt: 'Pipe Markers USA Logo',
  logoSrc: logo,
  link2: undefined,
  link3: undefined,
  link4: undefined,
  action1: undefined,
  action2: undefined,
};

Navbar8.propTypes = {
  logoAlt: PropTypes.string,
  logoSrc: PropTypes.string,
  link2: PropTypes.element,
  link3: PropTypes.element,
  link4: PropTypes.element,
  action1: PropTypes.element,
  action2: PropTypes.element,
};

export default Navbar8;
