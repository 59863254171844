import React, { useState } from 'react';
import './catalog.css'; // Ensure your catalog styles are applied

const images = [
  require('../assets/images/pipe-markers-1.jpg'),
  require('../assets/images/pipe-markers-2.jpg'),
  require('../assets/images/pipe-markers-3.jpg'),
  require('../assets/images/pipe-markers-4.jpg'),
  require('../assets/images/pipe-markers-5.jpg'),
  require('../assets/images/pipe-markers-6.jpg'),
  require('../assets/images/pipe-markers-7.jpg'),
  require('../assets/images/pipe-markers-8.jpg'),
  require('../assets/images/pipe-markers-9.jpg'),
  require('../assets/images/pipe-markers-10.jpg'),
  require('../assets/images/pipe-markers-11.jpg'),
];

const CatalogPage = () => {
  const [modalProduct, setModalProduct] = useState(null);

  const products = [
    { id: 1, name: 'Natural Gas-Small', image: images[0], description: 'Description of Product 1' },
    { id: 2, name: 'Natural Gas-Large', image: images[1], description: 'Description of Product 2' },
    { id: 3, name: 'Domestic Hot Water Retention-Small', image: images[2], description: 'Description of Product 3' },
    { id: 4, name: 'Domestic Cold Water-Small', image: images[3], description: 'Description of Product 4' },
    { id: 5, name: 'Domestic Cold Water-Large', image: images[4], description: 'Description of Product 5' },
    { id: 6, name: 'Domestic Hot Water-Small', image: images[5], description: 'Description of Product 6' },
    { id: 7, name: 'Domestic Hot Water-Large', image: images[6], description: 'Description of Product 7' },
    { id: 8, name: 'Heating Return-Small', image: images[7], description: 'Description of Product 8' },
    { id: 9, name: 'Heating Return-Large', image: images[8], description: 'Description of Product 9' },
    { id: 10, name: 'Heating Supply-Small', image: images[9], description: 'Description of Product 10' },
    { id: 11, name: 'Heating Supply-Large', image: images[10], description: 'Description of Product 11' },
  ];

  const openModal = (product) => {
    setModalProduct(product);
  };

  const closeModal = () => {
    setModalProduct(null);
  };

  return (
    <section id="product-catalog" className="catalog-page">
      <h1>Product Catalog</h1>
      <div className="catalog-grid">
        {products.map((product) => (
          <div
            key={product.id}
            className="product-card"
            onClick={() => openModal(product)}
          >
            <img src={product.image} alt={product.name} className="product-image" />
            <h2 className="product-name">{product.name}</h2>
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalProduct && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <h2 className="modal-name">{modalProduct.name}</h2>
            <div className="modal-body">
              <img src={modalProduct.image} alt={modalProduct.name} className="modal-image" />
              <p className="modal-description">{modalProduct.description}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CatalogPage;
